<template>
  <section id="hero">
    <v-img
      :max-height="700"
      :src="require('@/assets/home-hero.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="7"
          >
            <v-responsive
              class="d-flex align-center"
              height="100%"
              max-width="700"
              width="100%"
            >
              <base-heading title="OlapDB" />
              <base-subheading :title="$t('olapdbTitle')" />

              <base-body>
                {{$t('olapdbIntro')}}
              </base-body>

              <div
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
                class="d-flex flex-wrap"
              >
                <base-btn href="#discover-more">
                  {{$t('discoverMore')}}
                </base-btn>
              </div>
            </v-responsive>
          </v-col>

          <v-divider
            vertical
            color="grey"
            height="50%"
            class="mx-4"
          />

          <v-col
            cols="12"
            md="4"
          >
            <v-responsive
              class="d-flex align-center"
              height="100%"
              max-width="700"
              width="100%"
            >
              <div
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
                class="d-flex flex-wrap"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <base-btn
                      :ripple="false"
                      class="pa-1"
                      height="auto"
                      text
                      href="#welcome"
                    >
                      {{$t('introduction')}}
                    </base-btn>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <base-btn
                      :ripple="false"
                      class="pa-1"
                      height="auto"
                      text
                      href="#powerful-features"
                    >
                      {{$t('featureTitle')}}
                    </base-btn>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <base-btn
                      :ripple="false"
                      class="pa-1"
                      height="auto"
                      text
                      href="#service-and-components"
                    >
                      {{$t('componentTitle')}}
                    </base-btn>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <base-btn
                      :ripple="false"
                      class="pa-1"
                      height="auto"
                      text
                      href="#how-to-start"
                    >
                      {{$t('startTitle')}}
                    </base-btn>
                  </v-col>

                  <!--<v-col-->
                    <!--cols="12"-->
                    <!--md="12"-->
                  <!--&gt;-->
                    <!--<base-btn-->
                      <!--:ripple="false"-->
                      <!--class="pa-1"-->
                      <!--height="auto"-->
                      <!--text-->
                      <!--:to="{name: 'License'}"-->
                    <!--&gt;-->
                      <!--{{$t('purchaseLicense')}}-->
                    <!--</base-btn>-->
                  <!--</v-col>-->
                </v-row>
              </div>
            </v-responsive>
          </v-col>
        </v-row>

      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
